<script>
import ProcessDesign from "../layout/ProcessDesign.vue";
export default {
  name: "SubProcessDesign",
  components: {ProcessDesign},
  comments: {ProcessDesign},
  data(){
    return {

    }
  },
  methods:{
    loadProcess(){

    }
  }
}
</script>

<template>
  <div>
    <process-design/>
  </div>
</template>

<style scoped lang="less">

</style>
